import React from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './item.module.scss';

type Props = {
  title: string | undefined;
  image: string | undefined;
  path: string | undefined;
};

export const Item: React.FunctionComponent<Props> = (props) => {
  const {title, image, path} = props;
  const navigate = useNavigate();

  const redirectToPath = () => {
    navigate(`${path}`);
  };

  return (
    <div className={styles.item} onClick={redirectToPath}>
      <p className={styles.title}>{title}</p>
      <img className={styles.image} src={image} alt="" />
    </div>
  );
};
