import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {allProductsLines, PRODUCTS_LINES} from '../../utils/constants/linesWithBrands';
import {IList, IProduct, ParamTypes} from '../../utils/types/types';

import {CustomButton} from '../../components/customButton/CustomButton';
import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';

import LinesList from './linesList/LinesList';
import LinesDescription from './linesDescription/LinesDescription';
import ProductsList from './productsList/ProductsList';

import RetinCollagenLine from './lines/retinCollagenLine/RetinCollagenLine';
import YuzuHoneyLine from './lines/yuzuHoneyLine/YuzuHoneyLine';
import BiomeLactoLine from './lines/biomeLactoLine/BiomeLactoLine';
import ProMoistureLine from './lines/proMoistureLine/ProMoistureLine';
import OriginalHerbWormwoodLine from './lines/originalHerbWormwoodLine/OriginalHerbWormwood';
import OriginalArtemisiaLine from './lines/originalArtemisiaLine/originalArtemisiaLine';
import HeartleafBlemishLine from './lines/heartleafBlemishLine/HeartleafBlemishLine';

import styles from './blockWithBrands.module.scss';


type BlockWithBrandsProps = {
  isHiddenLinesList?: boolean;
}

const linksLinesProduct = [
  'originalHerbWormwood',
  'originalArtemisia',
  'proMoisture',
  'yuzuHoney',
  'heartLeaf',
  'retinCollagen3dCore',
  'biome5lacto',
];

export const BlockWithBrands: React.FunctionComponent<BlockWithBrandsProps> = ({
  isHiddenLinesList = false,
}) => {

  const {lineProduct} = useParams<ParamTypes>();
  const clickOnLine = (index: number)=> {
    setActiveLine(_ => {
      setProductsList(productLines[index].products);
      setList(productLines[index].list);
      setActiveProduct('');
      return index;});
  };

  const productLines = PRODUCTS_LINES.map((line, linesIndex) => {
    return {title: line.title,
      desciprtion: line.desciprtion,
      path: line.path,
      products: line.products.map((product) => {
        return {...product, onClick: ()=>{setActiveProduct(product.title);}};}),
      list: line.list.map((product) => {
        return {...product, onClick: ()=>{setActiveProduct(product.name);}};}),
      onClick: ()=> {clickOnLine(linesIndex);}};
  });

  const [activeLine, setActiveLine] = useState<number>(() => {
    const activeLine = linksLinesProduct.findIndex(v => v === lineProduct);
    if(activeLine !== -1) {
      return activeLine;
    }
    return 0;
  });
  const [list, setList] = useState<IList[]>(() => {
    const activeLine = linksLinesProduct.findIndex(v => v === lineProduct);
    if(activeLine !== -1) {
      return productLines[activeLine].list;
    }
    return productLines[0].list;
  });
  const [productsList, setProductsList] = useState<IProduct[]>(() => {
    const activeLine = linksLinesProduct.findIndex(v => v === lineProduct);
    if(activeLine !== -1) {
      return productLines[activeLine].products;
    }
    return productLines[0].products;
  });

  const [activeProduct, setActiveProduct] = useState<string>('');
  const [isOpenDescription, setIsOpenDescription] = useState(false);
  const [isOpenProducts, setIsOpenProducts] = useState(false);
  const navigate = useNavigate();

  const handleClickWatchLineProduct = () => {
    navigate(`${productLines[activeLine].path}`);
  };

  function changeDescription(){
    setIsOpenDescription(_ => {
      setIsOpenProducts(false);
      return !isOpenDescription;
    });
  }

  function changeProducts(){
    setIsOpenProducts(_ => {
      setIsOpenDescription(false);
      return !isOpenProducts;
    });
  }

  function selectProductsBlock(){
    switch (productLines[activeLine].title) {
    case allProductsLines.RetinCollagen3DCore:
      return <RetinCollagenLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.HeartleafBlemish:
      return <HeartleafBlemishLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.ProMoisture:
      return <ProMoistureLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.BiomeLacto:
      return <BiomeLactoLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.YuzuHoney:
      return <YuzuHoneyLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.OriginalHerbWormwood:
      return <OriginalHerbWormwoodLine
        activeProduct={activeProduct}
        products={productsList}/>;
    case allProductsLines.originalArtemisia:
      return <OriginalArtemisiaLine
        activeProduct={activeProduct}
        products={productsList}/>;
    }
  }
  return (<>
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {!isHiddenLinesList && <LinesList lines={productLines} activeLine={activeLine}/>}
        <div className={styles.productsBlock}>
          {!isHiddenLinesList &&  <>
            <div className={styles.title}>
          Описание линейки _
            </div>
            <LinesDescription
              title={productLines[activeLine].title}
              description={productLines[activeLine].desciprtion}
              isOpenDescription={isOpenDescription}
              changeDescription={changeDescription}/>
          </>}
          <ProductsList
            isHiddenLinesList={isHiddenLinesList}
            title={productLines[activeLine].title}
            products={list}
            activeProduct={activeProduct}
            changeProducts={changeProducts}
            isOpen={isOpenProducts}/>
        </div>
      </div>
      <div className={styles.allProducts}>
        {selectProductsBlock()}
        {!isHiddenLinesList && <CustomButton
          variant={ButtonVariant.Black}
          onClick={handleClickWatchLineProduct}
          className={styles.buttonOpenLine}>
          {'Смотреть линейку'}
        </CustomButton>}
      </div>
    </div>
    <div className={styles.containerMobile}>
      {!isHiddenLinesList && <LinesList lines={productLines} activeLine={activeLine}/>}
      <div className={styles.allProducts}>
        {selectProductsBlock()}
        {!isHiddenLinesList && <CustomButton
          variant={ButtonVariant.Black}
          onClick={handleClickWatchLineProduct}
          className={styles.buttonOpenLine}>
          {'Смотреть линейку'}
        </CustomButton>}
      </div>
      <ProductsList
        isHiddenLinesList={isHiddenLinesList}
        title={productLines[activeLine].title}
        products={list}
        activeProduct={activeProduct}
        changeProducts={changeProducts}
        isOpen={isOpenProducts}/>
    </div>
  </>);
};

export default BlockWithBrands;
