import React, {useRef, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {getLine} from '../../redux/content/contentThunks';
import {setActiveLineWithProducts} from '../../redux/content/contentSlice';

import {Promo} from '../../modules/promo/Promo';
import {ScrollableBenefits} from '../../modules/scrollableBenefits/ScrollableBenefits';
import BlockWithBrands from '../../modules/blockWithBrands/BlockWithBrands';
import {SomeLines} from '../../modules/someLines/SomeLines';

import {ParamTypes} from '../../utils/types/types';

import {LoadingScreen} from '../loadingScreen/LoadingScreen';

import styles from './productsLine.module.scss';

export const ProductsLine: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {lineProduct} = useParams<ParamTypes>();
  const line = useAppSelector(state => state.contentSlice.line);
  const isLoading = useAppSelector(state => state.contentSlice.isLoading);
  const error = useAppSelector(state => state.contentSlice.error);

  useEffect(() => {
    dispatch(getLine(lineProduct!));
    dispatch(setActiveLineWithProducts(lineProduct));
  }, [pathname]);

  const ref = useRef<HTMLDivElement>(null);
  const handleMoveToBenefits = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  if (!isLoading && error) {
    return <h2 className={styles.error}>{error}</h2>;
  }

  if (isLoading) {
    return <LoadingScreen/>;
  }

  return (
    <div className={styles.main}>
      <Promo
        lineProduct={lineProduct}
        promo={line.promo}
        clickOnButton={handleMoveToBenefits}/>
      <ScrollableBenefits
        lineProduct={lineProduct}
        title={line.promo.title}
        description={line.highlights.description}
        benefitsList={line.highlights.list}/>
      <div className={styles.brand} ref={ref}>
        <BlockWithBrands isHiddenLinesList={true}/>
      </div>
      <div className={styles.lines}>
        <SomeLines lines={line.otherLines}/>
      </div>
    </div>
  );
};

export default ProductsLine;

