export const PATH = {
  home: '/',
  // fraijour: '/fraijour',
  lines: {
    heartLeaf: '/heartLeaf',
    originalArtemisia: '/originalArtemisia',
    originalHerbWormwood: '/originalHerbWormwood',
    yuzuHoney: '/yuzuHoney',
    retinCollagen3dCore: '/retinCollagen3dCore',
    proMoisture: '/proMoisture',
    biome5lacto: '/biome5lacto',
  },
  retinCollagen3dCoreLine: {
    oiltoFoamCleanser: '/retinCollagen3dCoreLine/oiltoFoamCleanser',
    radianceMask: '/retinCollagen3dCoreLine/radianceMask',
    cleansingFoam: '/retinCollagen3dCoreLine/cleansingFoam',
    cream: '/retinCollagen3dCoreLine/cream',
    ampouleMist: '/retinCollagen3dCoreLine/ampouleMist',
    eyeCream: '/retinCollagen3dCoreLine/eyeCream',
    toner: '/retinCollagen3dCoreLine/toner',
    ampoule: '/retinCollagen3dCoreLine/ampoule',
  },
  yuzuHoneyLine: {
    cleansingBalm: '/yuzuHoneyLine/cleansingBalm',
    enrichedCream: '/yuzuHoneyLine/enrichedCream',
    lipSleepingMask: '/yuzuHoneyLine/lipSleepingMask',
    antiMelaCapsuleMask: '/yuzuHoneyLine/antiMelaCapsuleMask',
    capsuleEyeCream: '/yuzuHoneyLine/capsuleEyeCream',
    allClearCleansingFoam: '/yuzuHoneyLine/allClearCleansingFoam',
    essentialToner: '/yuzuHoneyLine/essentialToner',
  },
  originalHerbWormwoodLine: {
    o2Maskpack: '/originalHerbWormwoodLine/o2Maskpack',
    sheetMask: '/originalHerbWormwoodLine/originaLsheetMask',
    lipSleepingMask: '/originalHerbWormwoodLine/originLipSleepingMask',
    peelingGel: '/originalHerbWormwoodLine/peelingGel',
    phaPeelingEssence: '/originalHerbWormwoodLine/phaPeelingEssence',
    cleansingOil: '/originalHerbWormwoodLine/cleansingOil',
    cleansingFoam: '/originalHerbWormwoodLine/originalCleansingFoam',
    enzymeCleansingPack: '/originalHerbWormwoodLine/enzymeCleansingPack',
    calmingWateryCream: '/originalHerbWormwoodLine/calmingWateryCream',
    cleansingWater: '/originalHerbWormwoodLine/cleansingWater',
    calmingToner: '/originalHerbWormwoodLine/calmingToner',
  },
  biome5lactoLine: {
    balanceToner: '/biome5lactoLine/balanceToner',
    retexturizingRosyMask: '/biome5lactoLine/retexturizingRosyMask',
    balanceMoisturizer: '/biome5lactoLine/balanceMoisturizer',
    balanceGelCleanser: '/biome5lactoLine/balanceGelCleanser',
    treatmentAmpoule: '/biome5lactoLine/treatmentAmpoule',
  },
  proMoistureLine: {
    enzymePowderWash: '/proMoistureLine/enzymePowderWash',
    creamyToner: '/proMoistureLine/creamyToner',
    intensiveCream: '/proMoistureLine/intensiveCream',
    milkToningClayMask: '/proMoistureLine/milkToningClayMask',
  },
  heartLeafLine: {
    blemishphBalancedCleansingFoam: '/heartLeafLine/blemishphBalancedCleansingFoam',
    blemishToner: '/heartLeafLine/blemishToner',
    intensiveCalmingEssence: '/heartLeafLine/intensiveCalmingEssence',
    blemishMoistureCream: '/heartLeafLine/blemishMoistureCream',
    poreMeltingGelMask: '/heartLeafLine/poreMeltingGelMask',
  },
  originalArtemisiaLine: {
    bubbleFacialFoam: '/originalArtemisiaLine/bubbleFacialFoam',
    essenceMist: '/originalArtemisiaLine/essenceMist',
    steamMaskPack: '/originalArtemisiaLine/steamMaskPack',
  },
};
