import React from 'react';

import {ReactComponent as Logo} from '../../assets/icons/logoWithoutText.svg';
import {ReactComponent as TextLogo} from '../../assets/icons/textInLogo.svg';
import background from '../../assets/images/fraijourMain/main/mainBackground.jpg';

import styles from './loadingScreen.module.scss';

export const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <img alt="background"
        src={background} className={styles.backgroundImg} />
      <div className={styles.logoWithTextWrapper}>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
        <div className={styles.textLogoWrapper}>
          <TextLogo />
        </div>
      </div>
    </div>
  );
};
