import React from 'react';

import heartleafLine from '../../assets/images/fraijourMain/productLine/heartleafBlemish.jpg';
import originalArtemisia from '../../assets/images/fraijourMain/productLine/originalArtemisia.jpg';
import yuzuHoneyLine from '../../assets/images/fraijourMain/productLine/yuzuHoneyLine.jpg';
import retinCollagen from '../../assets/images/fraijourMain/productLine/retineCollagenLine.jpg';
import proMoisture from '../../assets/images/fraijourMain/productLine/proMoistureLine.jpg';
import biomeLacto from '../../assets/images/fraijourMain/productLine/biomeLactoLine.jpg';
import originalHerbWormwoodLine
  from '../../assets/images/fraijourMain/productLine/originalHerbWormwoodLine.jpg';

import {PATH} from '../../utils/constants/routeConstants';

import {Item} from './item/Item';

import styles from './someLines.module.scss';

const customizelines = new Map([
  ['heartleaf blemish', {
    title: 'Heartleaf Blemish',
    image: heartleafLine,
    path: PATH.lines.heartLeaf,
  }],
  ['original artemisia', {
    title: 'original artemisia',
    image: originalArtemisia,
    path: PATH.lines.originalArtemisia,
  }],
  ['original herb wormwood', {
    title: 'Original Herb Wormwood',
    image: originalHerbWormwoodLine,
    path: PATH.lines.originalHerbWormwood,
  }],
  ['yuzu honey', {
    title: 'Yuzu Honey',
    image: yuzuHoneyLine,
    path: PATH.lines.yuzuHoney,
  }],
  ['retin-collagen 3d core', {
    title: 'Retin-Collagen 3D Core',
    image: retinCollagen,
    path: PATH.lines.retinCollagen3dCore,
  }],
  ['pro moisture', {
    title: 'Pro  Moisture',
    image: proMoisture,
    path: PATH.lines.proMoisture,
  }],
  ['biome 5-lacto', {
    title: 'Biome 5-Lacto',
    image: biomeLacto,
    path: PATH.lines.biome5lacto,
  }],
]);

type Props = {
  lines: string[];
};

export const SomeLines: React.FunctionComponent<Props> = ({lines}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Другие линейки Frajour
      </h2>
      <div className={styles.grid}>
        {lines.map((line, index) => {
          const customizeline = customizelines.get(line.toLowerCase());
          return <Item
            title={customizeline?.title}
            image={customizeline?.image}
            path={customizeline?.path}
            key={index}/>;
        })}
      </div>
    </div>
  );
};
