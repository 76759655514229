import {PATH} from './routeConstants';

export enum allProductsLines {
  'OriginalHerbWormwood' = 'Original herb wormwood',
  'originalArtemisia' = 'Original Artemisia',
  'RetinCollagen3DCore' = 'Retin-Collagen 3D Core',
  'HeartleafBlemish' = 'Heartleaf Blemish',
  'ProMoisture' = 'Pro moisture',
  'BiomeLacto' = 'Biome 5-Lacto',
  'YuzuHoney' = 'Yuzu honey',
}

export const retinCollagenList = [
  {name: 'Oil to Foam Cleanser', path: PATH.retinCollagen3dCoreLine.oiltoFoamCleanser},
  {name: 'Cleansing Foam', path: PATH.retinCollagen3dCoreLine.cleansingFoam},
  {name: 'Radiance Mask', path: PATH.retinCollagen3dCoreLine.radianceMask},
  {name: 'Toner', path: PATH.retinCollagen3dCoreLine.toner},
  {name: 'Eye Cream', path: PATH.retinCollagen3dCoreLine.eyeCream},
  {name: 'Ampoule', path: PATH.retinCollagen3dCoreLine.ampoule},
  {name: 'Cream', path: PATH.retinCollagen3dCoreLine.cream},
  {name: 'Ampoule Mist', path: PATH.retinCollagen3dCoreLine.ampouleMist},
];

export const RETIN_COLLAGEN_PRODUCTS = [
  {
    title: 'Oil to Foam Cleanser',
    description: `Инновационный подход к глубокому и 
    комфортному очищению кожи с новым средством 2-в-1.`,
    path: PATH.retinCollagen3dCoreLine.oiltoFoamCleanser,
  },
  {
    title: 'Radiance Mask',
    description: `Один продукт и ваша кожа здоровая 
    и сияющая, как после хорошего 8 часового сна.`,
    path: PATH.retinCollagen3dCoreLine.radianceMask,
  },
  {
    title: 'Cleansing Foam',
    description: `Нежный очищающий гель для умывания на основе 
    аминокислот мягко очищает кожу, не оставляя чувства стянутости.`,
    path: PATH.retinCollagen3dCoreLine.cleansingFoam,
  },
  {
    title: 'Cream',
    description: `Моделирующий лифтинг-крем для лица, результат 
    от использования которого вы увидите уже через 1 неделю применения.`,
    path: PATH.retinCollagen3dCoreLine.cream,
  },
  {
    title: 'Ampoule Mist',
    description: `Откройте двери к увлажнённой и упругой 
    коже через прикосновение миста с вашей кожей.`,
    path: PATH.retinCollagen3dCoreLine.ampouleMist,
  },
  {
    title: 'Eye Cream',
    description: `Интенсивно питает и увлажняет нежную 
    кожу вокруг глаз, подтягивая её изнутри.`,
    path: PATH.retinCollagen3dCoreLine.eyeCream,
  },
  {
    title: 'Toner',
    description: `Увлажняющий тонер для лица, который 
    завершит этап очищения и подарит коже мягкость и эластичность.`,
    path: PATH.retinCollagen3dCoreLine.toner,
  },
  {
    title: 'Ampoule',
    description: `Забота о коже на клеточном уровне 
    с кремовой сывороткой Retin-Collagen 3D Core Ampoule.`,
    path: PATH.retinCollagen3dCoreLine.ampoule,
  },
];

export const heartleafBlemishList = [
  {name: 'Blemish pH Balanced Cleansing Foam',
    path: PATH.heartLeafLine.blemishphBalancedCleansingFoam},
  {name: 'Pore Melting Gel Mask', path: PATH.heartLeafLine.poreMeltingGelMask},
  {name: 'Blemish Toner', path: PATH.heartLeafLine.blemishToner},
  {name: 'Intensive Calming Essence', path: PATH.heartLeafLine.intensiveCalmingEssence},
  {name: 'Blemish Moisture Cream', path: PATH.heartLeafLine.blemishMoistureCream},
];

const HEARTLEAF_BLEMISH_PRODUCTS = [
  {
    title: 'Blemish pH Balanced Cleansing Foam',
    description: `Откройте двери к идеальной коже с уникальной пенкой для умывания. 
    Разработана для чувствительной, реактивной и проблемной кожи, сохраняет гидролипидный барьер.`,
    path: PATH.heartLeafLine.blemishphBalancedCleansingFoam,
  },
  {
    title: 'Blemish Toner',
    description: `Гипоаллергенный уход за кожей с тонером для лица. 
    Продукт обладает противовоспалительным действием и успокаивает раздраженную кожу.`,
    path: PATH.heartLeafLine.blemishToner,
  },
  {
    title: 'Intensive Calming Essence',
    description: `Наша эссенция для лица - идеальное решение для тех, 
    кто имеет чувствительную, раздраженную кожу. Дерматологически протестированная 
    формула гарантирует максимальную безопасность и эффективность.`,
    path: PATH.heartLeafLine.intensiveCalmingEssence,
  },
  {
    title: 'Blemish Moisture Cream',
    description: `Новая глава в уходе за кожей с нашим специально разработанным 
    кремом для лица, который предназначен для сухой, реактивной, склонной к акне и 
    чувствительной кожи. Он образует барьер, который предотвращает потерю влаги и 
    поддерживает увлажненность кожи в течение всего дня.`,
    path: PATH.heartLeafLine.blemishMoistureCream,
  },
  {
    title: 'Pore Melting Gel Mask',
    description: `Инновационны подход к одновременному 
    глубокому очищению и мощному восстановлению кожи.`,
    path: PATH.heartLeafLine.poreMeltingGelMask,
  },
];

export const proMoistureList = [
  {name: 'Enzyme Powder Wash', path: PATH.proMoistureLine.enzymePowderWash},
  {name: 'Milk Toning Clay Mask', path: PATH.proMoistureLine.milkToningClayMask},
  {name: 'Creamy Toner', path: PATH.proMoistureLine.creamyToner},
  {name: 'Intensive Cream', path: PATH.proMoistureLine.intensiveCream},
];

const PRO_MOISTURE_PRODUCTS = [
  {
    title: 'Enzyme Powder Wash',
    description: `Глубокое очищение кожи без раздражения и 
    чувства стянутости.`,
    path: PATH.proMoistureLine.enzymePowderWash,
  },
  {
    title: 'Creamy Toner',
    description: `Средство, которое смягчает как тонер, 
    увлажняет как крем.`,
    path: PATH.proMoistureLine.creamyToner,
  },
  {
    title: 'Intensive Cream',
    description: `Интенсивный крем, дарящий ощущение свежести, 
    увлажнения и гладкости.`,
    path: PATH.proMoistureLine.intensiveCream,
  },
  {
    title: 'Milk Toning Clay Mask',
    description: `Настоящее увлажнение и глубокое очищение 
    благодаря комбинации 3 видов глины и молочных протеинов.`,
    path: PATH.proMoistureLine.milkToningClayMask,
  },
];

export const biomeLactoList = [
  {name: 'Balance Gel Cleanser', path: PATH.biome5lactoLine.balanceGelCleanser},
  {name: 'Retexturizing Rosy Mask', path: PATH.biome5lactoLine.retexturizingRosyMask},
  {name: 'Balance Toner', path: PATH.biome5lactoLine.balanceToner},
  {name: 'Treatment Ampoule', path: PATH.biome5lactoLine.treatmentAmpoule},
  {name: 'Balance Moisturizer', path: PATH.biome5lactoLine.balanceMoisturizer},
];

const BIOME_LACTO_PRODUCTS = [
  {
    title: 'Balance Toner',
    description: `Сбалансированный тонер помогает защитить кожу от внешних 
    факторов окружающей среды и нормализовать ph-баланс. 
    Средство мгновенно впитывается, обеспечивая глубокое увлажнение 
    и гладкость без ощущения липкости.`,
    path: PATH.biome5lactoLine.balanceToner,
  },
  {
    title: 'Balance Moisturizer',
    description: `Идеальное решение для интенсивного восстановления и 
    увлажнения вашей кожи - это крем Biome 5-Lacto Balance Moisturizer.`,
    path: PATH.biome5lactoLine.balanceMoisturizer,
  },
  {
    title: 'Treatment Ampoule',
    description: `Ампульная сыворотка Biome 5-Lacto – это настоящее 
    спасение для кожи, которая нуждается в защите и восстановлении баланса. 
    Обладает омолаживающими и увлажняющими свойствами.`,
    path: PATH.biome5lactoLine.treatmentAmpoule,
  },
  {
    title: 'Retexturizing Rosy Mask',
    description: `Подарить заботу и восстановление для своей кожи стало возможным 
    с маской Retexturizing Rosy Mask. Это не просто уход - это долгосрочный 
    вклад в здоровый и сияющий внешний вид.`,
    path: PATH.biome5lactoLine.retexturizingRosyMask,
  },
  {
    title: 'Balance Gel Cleanser',
    description: `Сделать ежедневный ритуал очищения кожи 
    с деликатным гелем Biome 5-Lacto не только приятно, но и полезно. 
    Защита микробиома кожи в ваших руках.`,
    path: PATH.biome5lactoLine.balanceGelCleanser,
  },
];

export const yuzuHoneyList = [
  {name: 'Cleansing Balm', path: PATH.yuzuHoneyLine.cleansingBalm},
  {name: 'All Clear Cleansing Foam', path: PATH.yuzuHoneyLine.allClearCleansingFoam},
  {name: 'Anti-Mela Capsule Mask', path: PATH.yuzuHoneyLine.antiMelaCapsuleMask},
  {name: 'Essential Toner', path: PATH.yuzuHoneyLine.essentialToner},
  {name: 'Capsule Eye Cream', path: PATH.yuzuHoneyLine.capsuleEyeCream},
  {name: 'Enriched Cream', path: PATH.yuzuHoneyLine.enrichedCream},
  {name: 'Lip Sleeping Mask', path: PATH.yuzuHoneyLine.lipSleepingMask},
];

const YUZU_HONEY_PRODUCTS = [
  {
    title: 'Cleansing Balm',
    description: `Нежный щербет для глубокого очищения стойкого макияжа, 
    пыли и других загрязнений на коже.`,
    path: PATH.yuzuHoneyLine.cleansingBalm,
  },
  {
    title: 'Enriched Cream',
    description: `Невероятно нежный крем для лица Enriched Cream 
    - это когда сама природа заботиться о вашей коже.`,
    path: PATH.yuzuHoneyLine.enrichedCream,
  },
  {
    title: 'Lip Sleeping Mask',
    description: `Забота о нежной коже губ - это та задача, 
    с которой прекрасно справляется ночная маска для губ. 
    А невероятный цитрусовый аромат создаст атмосферу ночи в тропиках.`,
    path: PATH.yuzuHoneyLine.lipSleepingMask,
  },
  {
    title: 'Anti-Mela Capsule Mask',
    description: `Заряд естественного сияния, бодрости и хорошего 
    настроения и всё это о новой маске для лица Anti-Mela Capsule Mask.`,
    path: PATH.yuzuHoneyLine.antiMelaCapsuleMask,
  },
  {
    title: 'Capsule Eye Cream',
    description: `Лёгкий капсульный крем для области вокруг 
    глаз для профилактики первых признаков возрастных изменений и сияющего, яркого взгляда.`,
    path: PATH.yuzuHoneyLine.capsuleEyeCream,
  },
  {
    title: 'All Clear Cleansing Foam',
    description: `Гель-пенка для сияния и здоровья кожи 
    благодаря комфортному и глубокому очищению.`,
    path: PATH.yuzuHoneyLine.allClearCleansingFoam,
  },
  {
    title: 'Essential Toner',
    description: `Тонер для ежедневного применения, который 
    прекрасно завершает этап очищения и дарит вашей коже увлажнение и сияние.`,
    path: PATH.yuzuHoneyLine.essentialToner,
  },
];

export const herbWormwoodList = [
  {name: 'Cleansing Oil', path: PATH.originalHerbWormwoodLine.cleansingOil},
  {name: 'Cleansing Foam', path: PATH.originalHerbWormwoodLine.cleansingFoam},
  {name: 'Cleansing Water', path: PATH.originalHerbWormwoodLine.cleansingWater},
  {name: 'Peeling Gel', path: PATH.originalHerbWormwoodLine.peelingGel},
  {name: 'Enzyme Cleansing Pack', path: PATH.originalHerbWormwoodLine.enzymeCleansingPack},
  {name: 'O2 Maskpack', path: PATH.originalHerbWormwoodLine.o2Maskpack},
  {name: 'Sheet Mask', path: PATH.originalHerbWormwoodLine.sheetMask},
  {name: 'Calming Toner', path: PATH.originalHerbWormwoodLine.calmingToner},
  {name: 'PHA Peeling Essence', path: PATH.originalHerbWormwoodLine.phaPeelingEssence},
  {name: 'Calming Watery Cream', path: PATH.originalHerbWormwoodLine.enzymeCleansingPack},
  {name: 'Lip Sleeping Mask', path: PATH.originalHerbWormwoodLine.lipSleepingMask},
];

const HERB_WORMWOOD_PRODUCTS = [
  {
    title: 'O2 Maskpack',
    description: `Необычная и очень действенная маска, которая 
    помогает в борьбе с воспалениями, акне и черными точками, 
    сужает поры и убирает излишки себума.`,
    path: PATH.originalHerbWormwoodLine.o2Maskpack,
  },
  {
    title: 'Sheet Mask',
    description: `Комплекс из растительных экстрактов полыни, зеленого 
    чая, алоэ, центеллы азиатской и масла лаванды уменьшает раздражения, 
    успокаивает, увлажняет кожу и стимулирует процессы регенерации.`,
    path: PATH.originalHerbWormwoodLine.sheetMask,
  },
  {
    title: 'Lip Sleeping Mask',
    description: `Ночная маска, как маленькая фея, позаботиться 
    о красоте и сочности ваших губ пока вы спите.`,
    path: PATH.originalHerbWormwoodLine.lipSleepingMask,
  },
  {
    title: 'Peeling Gel',
    description: `Пилинг-гель поможет вам бороться с омертвевшими 
    клетками кожи и достичь идеального состояния вашей кожи. 
    Этот уникальный продукт сочетает в себе функции отшелушивания, 
    увлажнения и очищения кожи.`,
    path: PATH.originalHerbWormwoodLine.peelingGel,
  },
  {
    title: 'PHA Peeling Essence',
    description: `Невесомая лёгкая эссенция для бережного 
    избавления от омертвевших клеток`,
    path: PATH.originalHerbWormwoodLine.phaPeelingEssence,
  },
  {
    title: 'Cleansing Oil',
    description: `Идеальное решение для тщательного очищения кожи 
    и эффективного удаления самого стойкого макияжа. Содержит 
    натуральные масла и растительные экстракты, которые предотвращают 
    появление стянутости и сухости.`,
    path: PATH.originalHerbWormwoodLine.cleansingOil,
  },
  {
    title: 'Cleansing Foam',
    description: `Тщательно очищает кожу от макияжа, пыли и загрязнений. 
    Подходит для чувствительной кожи.`,
    path: PATH.originalHerbWormwoodLine.cleansingFoam,
  },
  {
    title: 'Enzyme Cleansing Pack',
    description: `Очищение кожи благодаря инновационному чудо-средству, 
    которое можно использовать в трёх разных форматах: очищающей пенки, 
    маски и пилинга.`,
    path: PATH.originalHerbWormwoodLine.enzymeCleansingPack,
  },
  {
    title: 'Calming Watery Cream',
    description: `Увлажнение с первых минут! 
    Натуральный успокаивающий увлажняющий крем с полынью.`,
    path: PATH.originalHerbWormwoodLine.calmingWateryCream,
  },
  {
    title: 'Cleansing Water',
    description: `Мягко и тщательно очищает кожу от самого 
    стойкого макияжа. Подходит для чувствительной кожи.`,
    path: PATH.originalHerbWormwoodLine.cleansingWater,
  },
  {
    title: 'Calming Toner',
    description: `Мягко удаляет верхний ороговевший слой кожи, 
    убирает излишки себума и очищает поры от загрязнений благодаря 
    входящим в состав AHA- и BHA-кислотам. Натуральный состав увлажняет 
    и успокаивает кожу, насыщает её витаминами и минералами, восстанавливает 
    водно-липидный баланс после умывания.`,
    path: PATH.originalHerbWormwoodLine.calmingToner,
  },
];

export const originalArtemisiaList = [
  {name: 'Bubble Facial Foam', path: PATH.originalArtemisiaLine.bubbleFacialFoam},
  {name: 'Essence Mist', path: PATH.originalArtemisiaLine.essenceMist},
  {name: 'Steam Mask Pack', path: PATH.originalArtemisiaLine.steamMaskPack},
];

const ORIGINAL_ARTEMISIA_PRODUCTS = [
  {
    title: 'Bubble Facial Foam',
    description: `Мягкое облако ежедневной заботы о вашей 
    коже в виде гипоаллергенной пенки для очищения лица`,
    path: PATH.originalArtemisiaLine.bubbleFacialFoam,
  },
  {
    title: 'Essence Mist',
    description: `100% экстракт полыни для мгновенного 
    успокоения кожи лица`,
    path: PATH.originalArtemisiaLine.essenceMist,
  },
  {
    title: 'Steam Mask Pack',
    description: `Разогревающая маска с полынью для глубоко 
    очищения, омоложения и ровного, сияющего тона лица`,
    path: PATH.originalArtemisiaLine.steamMaskPack,
  },
];

export const PRODUCTS_LINES = [
  {
    title: allProductsLines.OriginalHerbWormwood,
    desciprtion: `Линейка с натуральными ингредиентами и мягким воздействием 
    для нормальной, комбинированной и проблемной кожи`,
    products: HERB_WORMWOOD_PRODUCTS,
    list: herbWormwoodList,
    path: PATH.lines.originalHerbWormwood,
  },
  {
    title: allProductsLines.originalArtemisia,
    desciprtion: `Природные ингредиенты, лежащие в основе линейки Original 
    Artemisia, с бережной заботой ухаживают за вашей кожей`,
    products: ORIGINAL_ARTEMISIA_PRODUCTS,
    list: originalArtemisiaList,
    path: PATH.lines.originalArtemisia,
  },
  {
    title: allProductsLines.ProMoisture,
    desciprtion: `Непревзойдённое увлажнение и забота даже для самой 
    обезвоженной кожи`,
    products: PRO_MOISTURE_PRODUCTS,
    list: proMoistureList,
    path: PATH.lines.proMoisture,
  },
  {
    title: allProductsLines.YuzuHoney,
    desciprtion: `Сила природы и новейших разработок, лежащих в 
    основе линейки Yuzu Honey, подарят заряд бодрости на весь день`,
    products: YUZU_HONEY_PRODUCTS,
    list: yuzuHoneyList,
    path: PATH.lines.yuzuHoney,
  },
  {
    title: allProductsLines.HeartleafBlemish,
    desciprtion: `Мягкий, гипоаллергенный ежедневный уход, который 
    подойдёт даже для самой чувствительной и раздражённой кожи`,
    products: HEARTLEAF_BLEMISH_PRODUCTS,
    list: heartleafBlemishList,
    path: PATH.lines.heartLeaf,
  },
  {
    title: allProductsLines.RetinCollagen3DCore,
    desciprtion: `Повышение эластичности кожи уже после одной недели 
    применения продуктов линейки Retin-Collagen`,
    products: RETIN_COLLAGEN_PRODUCTS,
    list: retinCollagenList,
    path: PATH.lines.retinCollagen3dCore,
  },
  {
    title: allProductsLines.BiomeLacto,
    desciprtion: `Линейка Fraijour Biome 5-Lacto специально разработана 
    для поддержания здоровья баланса микробиома кожи`,
    products: BIOME_LACTO_PRODUCTS,
    list: biomeLactoList,
    path: PATH.lines.biome5lacto,
  },
];
