export enum ButtonVariant {
  Primary,
  Light,
  OutLine,
  BlurEmpty,
  Portage,
  Ronchi,
  Indigo,
  AquaForest,
  GreenSmoke,
  NewYorkPink,
  PortageTotal,
  PrimaryTotal,
  RonchiTotal,
  IndigoTotal,
  AquaForestTotal,
  GreenSmokeTotal,
  NewYorkPinkTotal,
  White,
  Black,
  WhiteWisteria,
  Transparent,
  WhiteDolphin,
  WhiteLilac,
  BlueGem,
}
