import bottle1 from '../../assets/images/fraijourMain/main/bottle1.png';
import bottle2 from '../../assets/images/fraijourMain/main/bottle2.png';
import bottle3 from '../../assets/images/fraijourMain/main/bottle3.png';
import bottle4 from '../../assets/images/fraijourMain/main/bottle4.png';
import bottle5 from '../../assets/images/fraijourMain/main/bottle5.png';
import bottle6 from '../../assets/images/fraijourMain/main/bottle6.png';
import backgroundBottle1
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle1.jpg';
import backgroundBottle2
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle2.jpg';
import backgroundBottle3
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle3.jpg';
import backgroundBottle4
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle4.jpg';
import backgroundBottle5
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle5.jpg';
import backgroundBottle6
  from '../../assets/images/fraijourMain/backgroundForBottle/backgroundBottle6.jpg';
import productLine1 from '../../assets/images/fraijourMain/productLinePopover/productLine1.jpg';
import productLine2 from '../../assets/images/fraijourMain/productLinePopover/productLine2.jpg';
import productLine3 from '../../assets/images/fraijourMain/productLinePopover/productLine3.jpg';
import productLine4 from '../../assets/images/fraijourMain/productLinePopover/productLine4.jpg';
import productLine5 from '../../assets/images/fraijourMain/productLinePopover/productLine5.jpg';
import productLine6 from '../../assets/images/fraijourMain/productLinePopover/productLine6.jpg';
import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';
import {ColorVariant} from '../enums/enum';
import {IBottle} from '../types/types';

import {PATH} from './routeConstants';

export const bottles: IBottle[] = [
  {
    bottleUrl: bottle1,
    popoverValues: {
      lineName: 'Biome 5-Lacto',
      productName: 'Balance Moisturizer',
      description: 'Идеальное решение для интенсивного восстановления и увлажнения вашей кожи',
      buttonColor: ButtonVariant.NewYorkPink,
      textColor: ColorVariant.NewYorkPink,
      productLineUrl: productLine1,
    },
    pathLine: PATH.lines.biome5lacto,
    pathProduct: PATH.biome5lactoLine.balanceMoisturizer,
    background: {
      url: backgroundBottle1,
      color: '#D97D74',
    },
    popoverStyle: {position: 'absolute', left: '50%', bottom: '30%'},
  },
  {
    bottleUrl: bottle2,
    popoverValues: {
      lineName: 'Pro Moisture',
      productName: 'Creamy Toner',
      description: 'Средство, которое смягчает как тонер, увлажняет как крем',
      buttonColor: ButtonVariant.Indigo,
      textColor: ColorVariant.Indigo,
      productLineUrl: productLine2,
    },
    pathLine: PATH.lines.proMoisture,
    pathProduct: PATH.proMoistureLine.creamyToner,
    background: {
      url: backgroundBottle2,
      color: '#617EC1',
    },
    popoverStyle: {position: 'absolute', left: '80%', bottom: '30%'},
  },
  {
    bottleUrl: bottle3,
    popoverValues: {
      lineName: 'Original Wormwood',
      productName: 'PHA Peeling Essence',
      description: 'Невесомая лёгкая эссенция для бережного избавления от омертвевших клеток',
      buttonColor: ButtonVariant.AquaForest,
      textColor: ColorVariant.AquaForest,
      productLineUrl: productLine3,
    },
    pathLine: PATH.lines.originalHerbWormwood,
    pathProduct: PATH.originalHerbWormwoodLine.phaPeelingEssence,
    background: {
      url: backgroundBottle3,
      color: '#609A6F',
    },
    popoverStyle: {position: 'absolute', left: '70%', bottom: '25%'},
  },
  {
    bottleUrl: bottle4,
    popoverValues: {
      lineName: 'Heartleaf',
      productName: 'Blemish pH Balanced Cleansing Foam',
      description: 'Откройте двери к идеальной коже с уникальной пенкой для умывания',
      buttonColor: ButtonVariant.GreenSmoke,
      textColor: ColorVariant.GreenSmoke,
      productLineUrl: productLine4,
    },
    pathLine: PATH.lines.heartLeaf,
    pathProduct: PATH.heartLeafLine.blemishphBalancedCleansingFoam,
    background: {
      url: backgroundBottle4,
      color: '#A0B264',
    },
    popoverStyle: {position: 'absolute', left: '70%', bottom: '25%'},
  },
  {
    bottleUrl: bottle5,
    popoverValues: {
      lineName: 'Retin-Collagen 3D Core',
      productName: 'Cream',
      description: 'Моделирующий лифтинг-крем для лица',
      buttonColor: ButtonVariant.Portage,
      textColor: ColorVariant.Portage,
      productLineUrl: productLine5,
    },
    pathLine: PATH.lines.retinCollagen3dCore,
    pathProduct: PATH.retinCollagen3dCoreLine.cream,
    background: {
      url: backgroundBottle5,
      color: '#9996ef',
    },
    popoverStyle: {position: 'absolute', left: '15%', bottom: '25%'},
  },
  {
    bottleUrl: bottle6,
    popoverValues: {
      lineName: 'Yuzu Honey',
      productName: 'All Cleansing Balm',
      description: `Нежный щербет для глубокого очищения стойкого 
      макияжа, пыли и других загрязнений на коже`,
      buttonColor: ButtonVariant.Ronchi,
      textColor: ColorVariant.Ronchi,
      productLineUrl: productLine6,
    },
    pathLine: PATH.lines.yuzuHoney,
    pathProduct: PATH.yuzuHoneyLine.cleansingBalm,
    background: {
      url: backgroundBottle6,
      color: '#E8BF56',
    },
    popoverStyle: {position: 'absolute', left: '-10%', bottom: '25%'},
  },
];
