import React, {useState, useEffect} from 'react';
import cn from 'classnames';

import {NavLink} from 'react-router-dom';

import {ReactComponent as DotsNine} from '../../assets/icons/dotsNine.svg';
import {ReactComponent as LogoMain} from '../../assets/icons/logoMobile.svg';
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';

import {CustomButton} from '../customButton/CustomButton';

import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';

import {fraijourMenu, FraijourMenu} from '../../utils/constants/menu';
import {PATH} from '../../utils/constants/routeConstants';

import styles from './mobileMenu.module.scss';

const classNamesButton = new Map([
  [FraijourMenu.AquaForest, styles.buttonAquaForest],
  [FraijourMenu.GreenSmoke, styles.buttonGreenSmoke],
  [FraijourMenu.Indigo, styles.buttonIndigo],
  [FraijourMenu.NewYorkPink, styles.buttonNewYorkPink],
  [FraijourMenu.Portage, styles.buttonPortage],
  [FraijourMenu.Ronchi, styles.buttonRonchi],
]);

export const MobileMenu = () => {
  const [isShow, setIsShow] = useState<boolean>(false);
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [isShow]);
  const handleShow = () => {
    setIsShow(true);
  };

  const handleHide = () => {
    setIsShow(false);
  };
  return (
    <>
      <div className={styles.menuSvg} onClick={handleShow}>
        <DotsNine />
      </div>
      <div className={cn(styles.menu, {[styles.active]: isShow})}>
        <div className={styles.header}>
          <NavLink onClick={handleHide} className={styles.logo} to={PATH.home}>
            <LogoMain />
          </NavLink>
          <CustomButton
            onClick={handleHide}
            variant={ButtonVariant.OutLine}
            className={styles.btnCLose}
          >
            <Cross />
          </CustomButton>
        </div>
        <div className={styles.line} />
        <div className={styles.content}>
          <div className={styles.title}>Линейки _</div>
          <div className={styles.linksWrapper}>
            {fraijourMenu.map((item) => (
              <NavLink
                onClick={handleHide}
                key={item.title}
                className={({isActive}) =>
                  cn(classNamesButton.get(item.variant), styles.link)
                }
                to={item.link}
              >{item.title}</NavLink>
            ))}
          </div>
        </div>
        <div className={styles.lineSecond} />
        <div className={styles.additionalMenu}>
          <a
            className={styles.link}
            target="_blank"
            href="https://market.yandex.ru/business--fraijour-cosmetics/108095562" rel="noreferrer">
              Купить
          </a>
          <a
            className={styles.link}
            target="_blank"
            href="https://b24-i0e1qp.bitrix24.site/crm_form_vtarq/" rel="noreferrer">
              Обучение
          </a>
          <a
            className={styles.link}
            target="_blank"
            href="https://b24-i0e1qp.bitrix24.site/crm_form_ub8yq/" rel="noreferrer">
              Контакты
          </a>
        </div>
      </div>
    </>
  );
};
